export default [
  {
    header: 'Apps & Pages',
  },
  {
    title: 'Todo',
    route: 'apps-todo',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Transaction',
    icon: 'FileTextIcon',
    route: 'apps-order-list',
  },
  {
    title: 'Product',
    icon: 'PackageIcon',
    route: 'apps-e-commerce-product-list',
  },
  {
    title: 'Object',
    icon: 'ArchiveIcon',
    route: 'apps-object-list',
  },
  {
    title: 'Identity',
    icon: 'UserIcon',
    route: 'apps-users-list',
  },
  {
    title: 'Listing',
    icon: 'HomeIcon',
    children: [
      {
        title: 'List',
        route: 'apps-listing-list',
      },
      {
        title: 'Map',
        route: 'apps-listing-map',
      },
    ],
  },
]
