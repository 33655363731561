<template>
  <b-card-body>
    <b-row>

      <!-- Col: Salesperson -->
      <b-col
        cols="12"
        md="6"
        class="mt-md-0 mt-3 d-flex"
        order="2"
        order-md="1"
      >
        <p class="clearfix mb-0">
          Copyright {{ (new Date()).getFullYear() }}
          <a :to="orgContact.url" target="_blank">{{ orgContact.displayName}}</a>.
          <small>Powered by VuexyCRM</small>
        </p>
      </b-col>
    </b-row>
  </b-card-body>
</template>
<script>
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
  },
  setup() {
    const orgContact = $themeConfig.org.contact
    return {
      orgContact,
    }
  },
}
</script>
