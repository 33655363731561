/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import { $routeConfig } from '@routeConfig'
import dashboard from './dashboard'
import apps from './apps'
import pages from './pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'
//
const navItems = [
  ...$routeConfig.navItems.dashboard ? dashboard : [],
  ...$routeConfig.navItems.apps ? apps : [],
  ...$routeConfig.navItems.pages ? pages : [],
  ...$routeConfig.navItems.uiElements ? uiElements : [],
  ...$routeConfig.navItems.formAndTable ? formAndTable : [],
  ...$routeConfig.navItems.chartsAndMaps ? chartsAndMaps : [],
  ...$routeConfig.navItems.others ? others : [],
]

// Array of sections
export default navItems
