const { parseEnvVar } = require('./utils')

// eslint-disable-next-line import/prefer-default-export
export const $routeConfig = {
  routes: [
    'apps-quote-preview',
  ],
  navItems: {
    dashboard: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_DASHBOARD, true),
    apps: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_APPS, true),
    pages: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_PAGES, true),
    uiElements: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_UIELEMENTS, true),
    formAndTable: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_FORMANDTABLE, true),
    chartsAndMaps: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_CHARTSANDMAPS, true),
    others: parseEnvVar(process.env.VUEXYCRM_FRONTEND_ROUTECONFIG_NAVITEMS_OTHERS, true),
  },
}
