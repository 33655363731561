<template>
  <layout-horizontal>

    <router-view />

    <app-customizer
      v-if="showCustomizer && route === 'apps-listing-map'"
      slot="customizer"
    />
    <map-customizer
      v-if="route === 'apps-listing-map'"
      slot="mapCustomizer"
    />
  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import MapCustomizer from '@core/layouts/components/map-customizer/MapCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    LayoutHorizontal,
    AppCustomizer,
    MapCustomizer,
  },
  data() {
    return {
      showCustomizer: false && $themeConfig.layout.customizer,
      route: this.$route.name,
    }
  },
}
</script>
