export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-ecommerce',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
]
